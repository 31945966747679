
import { auth } from "./config/firebase"
import AuthContextProvider from './components/AuthContextProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Marvel from './pages/Marvel';
import Main from './pages/Main';
import Dc from './pages/Dc';
import Upload from "./pages/Upload";





function App() {



    console.log(auth?.currentUser?.email);
  


  return (
    <BrowserRouter>
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/marvel" element={<Marvel />} />
        <Route path="/dc" element={<Dc />} />
        <Route path="/upload" element={<Upload />} />
      </Routes>
    </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
