import { useState, useEffect } from "react";
import ComicCard from "../components/ComicCard";
import { useAuth } from "../components/AuthContextProvider";
import LoginCard from "../components/Logincard";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";

interface Item {
  id: number;
  series: string;
  issue: string;
  publisherId: number;
  superHeroId: number;
  imgUrl: string;
}

const Dc: React.FC = () => {
  const [comic, setComic] = useState<Item[]>([]);
  const [filteredComics, setFilteredComics] = useState<Item[]>([]);
  const [selectedSuperhero, setSelectedSuperhero] = useState<number | null>(null);
  const [allComics, setAllComics] = useState<boolean>(false);

  const authContext = useAuth();

  const { wantsToSignIn } = authContext;

  const skeletonImage = 'dc-logo.jpg'

  useEffect(() => {
    comics();
  }, []);

  const comics = async () => {
    const response = await fetch('https://ts-comicshop-28397a67a86b.herokuapp.com/api/comics');
    const comicsData = await response.json();
    const filteredComics = comicsData.filter((comic: { publisherId: number }) => comic.publisherId === 2);
    setComic(filteredComics);
  };
   
  useEffect(()=>{
    handleAllComics();
  },[])

  const handleAllComics = () => {
    setSelectedSuperhero(null);
    setAllComics(true)
  }


  const handleFilterComics = (superheroId: number) => {
    const filtered = comic.filter((dc: Item) => dc.superHeroId === superheroId && dc.publisherId === 2);
    console.log(filtered, "FILTERED")
    setAllComics(false)
    setFilteredComics(filtered);
    setSelectedSuperhero(superheroId);
  };

  return (
    <div>
      <Container>
      {wantsToSignIn === true ? (
        <LoginCard />
      ) : (
        <div className="row justify-content-center text-center px-10">
          <div className="p-5  pb-5 items-center">
            {/* Render buttons for each superhero */}
            <button type="button" className="bubble-button  "onClick={() => handleAllComics()}>All DC</button>
            <button type="button" className="bubble-button  "onClick={() => handleFilterComics(7)}>Batman</button>
            <button type="button" className="bubble-button "onClick={() => handleFilterComics(8)}>Super-Man</button>
            <button type="button" className="bubble-button "onClick={() => handleFilterComics(9)}>Harley Quinn</button>
   
            {/* Add more buttons for other superheroes as needed */}
          </div>
          {selectedSuperhero !== null &&
            filteredComics.map((product) => (
              <div key={product.id} className="col-md-4 py-4">
                <ComicCard
                
                  id={product.id}
                  publisherId={product.publisherId}
                  superheroId={product.superHeroId}
                  series={product.series}
                  imgUrl={product.imgUrl}
                  issue={product.issue}
                  skeletonImg={skeletonImage}
                />
            </div>
            ))}
                 {allComics === true &&
            comic.map((product) => (
              <div key={product.id} className="col-md-4 py-4">
                <ComicCard
               
                  id={product.id}
                  publisherId={product.publisherId}
                  superheroId={product.superHeroId}
                  series={product.series}
                  imgUrl={product.imgUrl}
                  issue={product.issue}
                  skeletonImg={skeletonImage}
                />
                </div>
           
            ))}


        </div>
      )}
      </Container>
      <Footer/>
    </div>
  );
};

export default Dc;
