
import { Container, Form} from 'react-bootstrap'; // Import React Bootstrap components
import { Button } from "./button"
import {Card, CardTitle, CardContent} from "./Card";
import {useState, useRef} from 'react';
import AWS from 'aws-sdk';



const s3 = new AWS.S3({
    accessKeyId: 'AKIAQVL2JMXG2POA6EPP',
    secretAccessKey: '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',
    region: 'us-west-1',
});

const UploadCard: React.FC = () => {

    const [publisher, setPublisher] = useState<string>('1')
    const [superHero, setSuperHero] = useState<string>('1')
    const [issuePost, setIssuePost] = useState<string>('')
    const [seriesPost, setSeriesPost] = useState<string>('')
    const [submission, setSubmission] = useState<string>('')
    
    const formRef = useRef<HTMLFormElement>(null);

    const handleReset = (e: React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault();
        setSubmission('Upload Complete')
        if (formRef.current) {
            formRef.current.reset();
          }
    }


    const handleSetPublisher = (selectedPublisher : string) => {
        if (selectedPublisher === '1'){
            setSuperHero('1')
        }else{
            setSuperHero('7')
        }
        setPublisher(selectedPublisher)
        console.log(publisher, "PUBLISHER")
        console.log(superHero, "SUPERHERO")
    }

    const handleSetSuperHero= (selectedHero : string) => {
        setSuperHero(selectedHero)
    }

    const handleSeriesChange = (seriesInput: string) => {
        setSeriesPost(seriesInput)
    }

    const handleIssueChange = (issueInput: string) => {
        setIssuePost(issueInput)
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileName = e.target.files && e.target.files[0] && e.target.files[0].name;
        if (fileName) {
          console.log(fileName, "FILENAME");
      
        
        } else {
          console.log("No file selected");
        }
      };
      

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget as HTMLFormElement;
        const fileInput = form.querySelector('input[type="file"]') as HTMLInputElement;
        console.log(fileInput.name, "THIS IS FILE NAME")
        const file = fileInput.files && fileInput.files[0];
      
      

        const post = ( ) => {
        
            fetch("https://ts-comicshop-28397a67a86b.herokuapp.com/api/comics", {
                method: "POST",
                body: JSON.stringify({
                    series: seriesPost,
                    issue: Number(issuePost),
                    imgUrl: "https://comicshop.s3.us-west-1.amazonaws.com/"+ file?.name,
                    publisherId: Number(publisher),
                    superHeroId: Number(superHero)
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
                });
        }

            if (file) {
                const params = {
                    Bucket: 'comicshop',
                    Key: file.name,
                    Body: file,
                    ContentType: file.type, // Set the content type based on the file type
                    ACL: 'public-read',
                    ContentDisposition: 'inline'
                };

                try {
                    const data = await s3.upload(params).promise();
                    console.log('File uploaded:', data.Location);
            
                } catch (error) {
                    console.error('Error uploading file:', error);
                
                }
            }
            post()
        };
    
    console.log(superHero, "SUPER HERO")



    return(
        <Container className="mt-4 d-flex justify-content-center">
        <Card className='lg:px-4 sm:px-0 pt-8 mt-24 pb-8 lg:w-75'> 
                <CardContent className='px-20 '>
                    <CardTitle>Upload Comic</CardTitle>
                    <Form ref={formRef} onSubmit={(e) => {e.preventDefault();handleSubmit(e);handleReset(e)}}>
                   
                        <Form.Group className="mb-3">
                <Form.Select onChange={(e) => handleSetPublisher(e.target.value)}>
                    <option value="1">Marvel</option>
                    <option value="2">Dc</option>
                </Form.Select>
            </Form.Group>
                      
                        <Form.Group className="mb-3">
                { publisher === "1" ?
                <Form.Select onChange={(e) => handleSetSuperHero(e.target.value)}>
                    <option value="1">Venom</option>
                    <option value="2">Thor</option>
                    <option value="3">Iron Man</option>
                    <option value="4">X Men</option>
                    <option value="5">She Hulk</option>
                    <option value="6">Spider-Man</option>
                </Form.Select>
:
                <Form.Select onChange={(e) => handleSetSuperHero(e.target.value)}>
                    <option value="7">Batman</option>
                    <option value="8">Superman</option>
                    <option value="9">Harley Quinn</option>
                </Form.Select>
                }


            </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="Series" onChange={(e) => handleSeriesChange(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="number" placeholder="Issue" onChange={(e) => handleIssueChange(e.target.value)} required/>
                        </Form.Group>
                
                        <div className='img-div'>
                    <div>
                        <Form.Control type="file" id="fileInput" onChange={handleFileChange}  accept="image/*" required />
                    </div>
                    <div className='ml-'>
                        <Button type="submit" >Upload</Button>
                    </div>
                </div>
                        
                    </Form>
                    <p className='mt-2 mb-0 text-red-500'>{submission}!</p>
                </CardContent>
            </Card>
        </Container>
)

}






export default UploadCard;