import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardTitle } from './Card';

interface ComicCardProps {
  id: number;
  series: string;
  issue: string;
  publisherId: number;
  superheroId: number;
  imgUrl: string;
  skeletonImg: string;
 
  
}

const ComicCard: React.FC<ComicCardProps> = ({ superheroId, publisherId, series, imgUrl, issue, skeletonImg }) => {
  const superheroes = [null, 'Venom', '', 'Iron Man', 'X-Men', 'She-Hulk', 'Spider-Man', 'Batman', 'Super-Man', 'Harley Quinn'];
  const publishers = [null, 'Marvel', 'DC']

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 
  
    return () => clearTimeout(timer);
  }, [imgUrl]);
  

 
  return (
<Card className={`rotate-on-hover ${loading ? 'skeleton' : ''}`}>
  <CardContent className="p-2">
    <CardTitle className={`pb-2 ${loading ? '' : ''}`}>
      {loading ? publishers[publisherId] : superheroes[superheroId]}
    </CardTitle>

    <div>
      <img
        src={loading ? skeletonImg : imgUrl}
        alt={`${series} cover`}
        className='card-img-style'
       
      />

      <CardContent className={`pb-0 ${loading ? 'skeleton-text-body' : ''}`}>
        {loading ? '' : `${series}`}
      </CardContent>
      <CardContent className={`pb-0 ${loading ? 'skeleton-text' : ''}`}>
        {loading ? 'Loading' : `#${issue}`}
      </CardContent>
    </div>
  </CardContent>
</Card>

  );
};

export default ComicCard;
