import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import { Button } from './button';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuContent,
  NavigationMenuLink,
} from './navigation-menu';
import { useAuth } from '../components/AuthContextProvider';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, logOut, setWantsToSignIn, setUserSignIn} = useAuth();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    };

  const handleSetWantsToSignIn = () => {
    setWantsToSignIn(false)
  }
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const menus = [
    { title: 'Home', path: '/' },
    { title: 'Marvel', path: '/marvel' },
    { title: 'DC', path: '/dc' },
    { title: 'Upload a Comic', path: '/upload' },
  ];

  return (
    <nav className="bg-white w-full border-b md:border-0">
      <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8 ">
        <div className="flex items-right justify-between py-3 md:py-5 md:block">
          <button onClick={handleSetWantsToSignIn}>
          <Link className="no-underline" to="/" onClick={closeMenu}>
            <h1 className="text-3xl font-bold text-purple-600">Chompy's</h1>
          </Link>
          </button>
          <button className="" onClick={handleMenuToggle}>
          <div className="md:hidden collapse-btn mr-6 pr-10 -right">
            
              {/* Hamburger icon */}
          
          </div>
          </button>
        </div>
        <div
          className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${isMenuOpen ? 'block' : 'hidden'}`}
        >
          <ul className="justify-end items-center space-y-8 md:flex md:space-x-6  md:space-y-0">
            {menus.map((item, idx) => (
              <li key={idx} className="pt-4 pr- text-gray-600 hover:text-indigo-600">
                 <button className='' onClick={handleSetWantsToSignIn}>
                <Link className="no-underline" to={item.path} onClick={closeMenu}>
                  <p>{item.title}</p>
                </Link>
                </button>
              </li>
            ))}
           
              <NavigationMenu>
                <NavigationMenuList>
                  <NavigationMenuItem>
                 
                    {isMenuOpen === true ?       
                    <li className="text-gray-600 hover:text-indigo-600 ">
                     {isAuthenticated ? <button
                            className="  bg-transparent text-black hover:text-gray-300 hover:bg-gray-800 py-2 mt-2 px-2  border border-white rounded"
                            type="submit"
                            onClick={() => {logOut();closeMenu()}}
                            style={{ marginLeft: '-40px' }}
                         >
                            Log Out
                          </button> :       
                          
                          <Button
                            className="bg-transparent text-black hover:text-gray-300 hover:bg-gray-800 py-2 px- border border-white rounded"
                            type="submit"
                            onClick={() => {setWantsToSignIn(true);handleMenuToggle()}}
                            style={{ marginLeft: '-31px' }}
                          >
                            Log In
                          </Button> }

                          </li>
                      :
                    <NavigationMenuTrigger className='pt-1 pb-0'>
                      
                      <FaUser />
                    </NavigationMenuTrigger>
                    }                  
                    <NavigationMenuContent>
                      <NavigationMenuLink>
                        {isAuthenticated ? (
                          <Button
                            className="bg-transparent text-black hover:text-gray-300 hover:bg-gray-800 py-2 px-4 border border-white rounded"
                            onClick={logOut}
                          >
                            Log Out
                          </Button>
                        ) : (
                          <Button
                            className="bg-transparent text-black hover:text-gray-300 hover:bg-gray-800 py-2 px-4 border border-white rounded"
                            type="submit"
                            onClick={() => setWantsToSignIn(true)}
                          >
                            Log In
                          </Button>
                        )}
                        
                      </NavigationMenuLink>
                      
                    </NavigationMenuContent>
                
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
           
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
