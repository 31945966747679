import { useState, useEffect } from "react";
import ComicCard from "../components/ComicCard";
import { useAuth } from "../components/AuthContextProvider";
import LoginCard from "../components/Logincard";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";


interface Item {
  id: number;
  series: string;
  issue: string;
  publisherId: number;
  superHeroId: number;
  imgUrl: string;
}

const Marvel: React.FC = () => {
  const [comic, setComic] = useState<Item[]>([]);
  const [filteredComics, setFilteredComics] = useState<Item[]>([]);
  const [selectedSuperhero, setSelectedSuperhero] = useState<number | null>(null);
  const [allComics, setAllComics] = useState<boolean>(false);

  const authContext = useAuth();

  const { wantsToSignIn} = authContext;

  const skeletonImage = 'marvel.jpg'

  useEffect(() => {
    comics();
  }, []);

  useEffect(()=>{
    handleAllComics();
  },[]);


  const comics = async () => {
    const response = await fetch('https://ts-comicshop-28397a67a86b.herokuapp.com/api/comics');
    const comicsData = await response.json();
    const filteredComics = comicsData.filter((comic: { publisherId: number }) => comic.publisherId === 1);
    setComic(filteredComics);


  };
   
  const handleAllComics = () => {
    setSelectedSuperhero(null);
    setAllComics(true)
  }

  const handleFilterComics = (superheroId: number) => {
    const filtered = comic.filter((marvel: Item) => marvel.superHeroId === superheroId && marvel.publisherId === 1);
    console.log(filtered, "FILTERED")
    setAllComics(false)
    setFilteredComics(filtered);
    setSelectedSuperhero(superheroId);
  };

  return (
    <div>
   
    <Container className="px-0  w-100">
      
      {wantsToSignIn === true ? (
        <LoginCard />
      ) : (
        <div className="row justify-content-center text-center px-10">
          <div className="pt-5  pb-5 items-center">
            <button type="button" className="bubble-button " onClick={() => handleAllComics()}>All Marvel</button>
            <button type="button" className="bubble-button " onClick={() => handleFilterComics(1)}>Venom</button>
            <button type="button" className="bubble-button " onClick={() => handleFilterComics(3)}>Iron Man</button>
            <button type="button" className="bubble-button" onClick={() => handleFilterComics(4)}>X-Men</button>
            <button type="button" className="bubble-button" onClick={() => handleFilterComics(5)}>She Hulk</button>
            <button type="button" className="bubble-button" onClick={() => handleFilterComics(6)}>Spider-Man</button>

          </div>
          {selectedSuperhero !== null &&
            filteredComics.map((product) => (
              <div key={product.id} className="col-md-4 py-4">
                <ComicCard
                  id={product.id}
                  publisherId={product.publisherId}
                  superheroId={product.superHeroId}
                  series={product.series}
                  imgUrl={product.imgUrl}
                  issue={product.issue}
                  skeletonImg={skeletonImage}
                />
              </div>
            ))}
                 {allComics === true &&
            comic.map((product) => (
              <div key={product.id} className="col-md-4 py-4 ">
                <ComicCard
                  id={product.id}
                  publisherId={product.publisherId}
                  superheroId={product.superHeroId}
                  series={product.series}
                  imgUrl={product.imgUrl}
                  issue={product.issue}
                  skeletonImg={skeletonImage}
                />
              </div>
            ))}


        </div>
      )}
      
   
  
    </Container>
    <Footer/>
    </div>
  );
};

export default Marvel;
