import { Link } from "react-router-dom"
import { Github, Linkedin } from "lucide-react"

export default function Footer() {

    const email = "jordonomarchesano@gmail.com"

  return (
    <footer className="bg-gray-100 py-8 lg:py-12 mt-36 lg:mt-96 ">
    <div className="container flex flex-col items-center justify-center mx-auto lg:flex-row lg:justify-between">
        <div className="flex space-x-4 mt- lg:mt-0">
            <Link to={"/"}>
            Home
            </Link>
            <Link to={`mailto:${email}`}>
            Contact US
            </Link>
        </div>
        <div className="pb- text-center">
            <p className="sm:mt-0 lg:mr-4 lg:mt-4 mb-0 lg:pb-2">
            @2024 All rights reserved by Chompy's.
            </p>
        </div>
        <div className="flex space-x-4 mt- lg:mt-1">
            <Link to={"https://github.com/cHoMpDiSs"}>
            <Github />
            </Link>
            <Link to={"https://www.linkedin.com/in/jordon-m/"}>
            <Linkedin />
            </Link>
        </div>
    
        </div>
  </footer>
  
  )
}