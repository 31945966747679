import LoginCard from "../components/Logincard";
import { useAuth } from "../components/AuthContextProvider";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";



 

  
const Main: React.FC = () => {

  const authContext = useAuth();
  const { wantsToSignIn, isAuthenticated, firstName } = authContext;




    return (
        <div>
        <Container >
        <div className=" mt-32 ">
      
            {wantsToSignIn === true ? 
                <LoginCard
            
            /> : <div className="">{isAuthenticated ? 
            <h1 className="pl-2"> {firstName}</h1>
            : null}
    <div className="flex flex-wrap">
    <img alt="comic" className="p-2 animate-left" src="comics.jpg"/>
    <img alt="lego" className="w-1/2 md:w-1/2 lg:w-1/2 p-2 animate-bottom" src="lego.jpg" />
    <img alt="comic2" className="w-1/2 md:w-1/2 lg-w-1/2 p-2 animate-right" src="comics2.jpg" />
    <img alt="comic3" className="p-2 animate-top" src="comics3.jpg" />
</div>

            </div>
            
    }
    </div>
                 
                    </Container>
                    <div className="footer-main sm:mt-">
                        <Footer />
                    </div>
                      
                    </div>
    )
}




export default Main;






// interface MainProps {
//     greeting: string | null;
//     email: string;
//     setEmail: React.Dispatch<React.SetStateAction<string>>;
//     logInFail: boolean,
//     setLogInFail:React.Dispatch<React.SetStateAction<boolean>>,
//     password: string;
//     setPassword: React.Dispatch<React.SetStateAction<string>>;
//     firstName: string | null;
//     setFirstName: React.Dispatch<React.SetStateAction<string | null>>;
//     lastName: string;
//     setLastName: React.Dispatch<React.SetStateAction<string>>;
//     userSignIn: boolean;
//     setUserSignIn: React.Dispatch<React.SetStateAction<boolean>>;
//     isAuthenticated: boolean;
//     setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
//     signUp: () => void;
//     signIn: (email: string, password: string) => void;
//     signInWithGoogle: () => void;
//     logOut: () => void;
//     wantsToSignIn: boolean
//     setWantsToSignIn:React.Dispatch<React.SetStateAction<boolean>>;
//   }

