import React, { useEffect } from 'react';
import { useAuth } from './AuthContextProvider';
import { Card} from './Card';
import { Input } from './input';
import { Button } from './button';
import { FcGoogle } from 'react-icons/fc'; 
import { Container } from 'react-bootstrap';

const LoginCard: React.FC = () => {

  const {
    email,
    setEmail,
    password,
    setPassword,
    logInFail,
    setFirstName,
    setLastName,
    userSignIn,
    setUserSignIn,
    isAuthenticated,
    signInWithGoogle,
    signIn,
    signUp,


  } = useAuth();

  useEffect(() => {
    setUserSignIn(true);
  }, []);

  return (
    <Container className='d-flex justify-content-center align-items-center '>
        {userSignIn === true ? (
           <Card className="w-100 login-margin mt-20">
            <p className='ml-4 mt-2'>Sign Up</p>
            <Input className="btn-txt" placeholder="First Name.." onChange={(e) => setFirstName(e.target.value)} />
            <Input className="btn-txt" placeholder="Last Name.." onChange={(e) => setLastName(e.target.value)} />
            <Input className="btn-txt" placeholder="Email.." onChange={(e) => setEmail(e.target.value)} />
            <Input className="btn-txt" type="password" placeholder="Password.." onChange={(e) => setPassword(e.target.value)} />
            <div className="text-center mt-3 ">
            <Button className='log-btn' onClick={() => { signUp(); setUserSignIn(false); }}>Register</Button>
            <button className='log-btn flex items-center justify-center' onClick={signInWithGoogle}><FcGoogle className='google-img m-0 mt-2 p-0'/></button>
            </div>
            <div className='text-center mb-4'>
            <p className="mt-2 mb-2">already have an account?</p>
            <Button id="myLinkButton"  onClick={() => setUserSignIn(false)}>Sign In</Button>
            </div>
          </Card>
        ) : (
          <div className="container justify-content-center d-flex align-items-center">
           
            {isAuthenticated === false ? (
             <Card className='sign-in-card mt-20'>
               
                {logInFail === true ? <p className='incorrect'>Incorrect credentials</p> :  <p>Please sign in.</p>}
                <div className="text-center mx-auto w-75">
                  <Input placeholder="Email.." onChange={(e) => setEmail(e.target.value)} />
                  <Input type="password" placeholder="Password.." onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="mt-2 text-center">
                  <Button className='mt-3'  onClick={(e) => { signIn( email, password) }}>Sign in</Button>
                </div>
                </Card>
              
            ) : null}
          </div>
          
        )}
        
      </Container>
  
    
  );
            };  

export default LoginCard;
