import { Container } from "react-bootstrap";
import { useAuth } from "../components/AuthContextProvider";
import Footer from "../components/Footer";
import LoginCard from "../components/Logincard";
import UploadCard from "../components/UploadCard";
import { useEffect } from "react";


const Upload: React.FC = () =>{

 

    const {isAuthenticated, setUserSignIn} = useAuth()


    useEffect(() => {
        setUserSignIn(true);
      }, []);

      
    return (
       
        <div>
             <Container>
             {isAuthenticated === false ? (
                <div>
                <h2 className="mt-2">You must be logged in....</h2>
        <LoginCard />
        </div>
      ) : <UploadCard/>}
      </Container>
      <Footer/>
                </div>  
    
    )
}



export default Upload;