import React, { useState, useContext, createContext, ReactNode } from 'react';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  UserCredential,
} from 'firebase/auth';

import { auth, googleProvider } from '../config/firebase';
import Navbar from './Navbar';

interface AuthContextType {
  greeting: string | null;
  email: string;
  setEmail: (email: string) => void;
  logInFail: boolean;
  setLogInFail: (fail: boolean) => void;
  setWantsToSignIn: (wantsToSignIn: boolean) => void;
  wantsToSignIn: boolean;
  password: string;
  setPassword: (password: string) => void;
  signInWithGoogle: () => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  firstName: string | null;
  setFirstName: (firstName: string | null) => void;
  lastName: string | null;
  setLastName: (lastName: string | null) => void;
  userSignIn: boolean;
  setUserSignIn: (userSignIn: boolean) => void;
  signIn: (email: string, password: string) => void;
  signUp: () => void;
  logOut: () => void;
  
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [email, setEmail] = useState<string>("");
  const [greeting, setGreeting] = useState<string | null>("");
  const [password, setPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string | null>("please sign in");
  const [lastName, setLastName] = useState<string | null>("");
  const [userSignIn, setUserSignIn] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [wantsToSignIn, setWantsToSignIn] = useState<boolean>(false);
  const [logInFail, setLogInFail] = useState<boolean>(false);

  const signUp = async () => {
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(userCredential.user, {
        displayName: `${firstName} ${lastName}`,
      });
      setFirstName(firstName);
      setLastName(lastName);
      console.log("Sign up successful");
    } catch (err) {
      console.error(err);
    }
  };

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      if (auth.currentUser) {
        const fullName = auth.currentUser.displayName || null;
        setFirstName(fullName);
        setGreeting(fullName);
        setIsAuthenticated(true);
        setWantsToSignIn(false);
      }
    } catch (err) {
      console.log(err);
      setLogInFail(true);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      if (auth.currentUser) {
        const fullName = auth.currentUser.displayName || null;
        setFirstName(fullName);
        setGreeting(fullName);
        setUserSignIn(false);
        setIsAuthenticated(true);
        setWantsToSignIn(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      console.log("Sign out successful");
      setGreeting("");
      setUserSignIn(true);
      setIsAuthenticated(false);
      setWantsToSignIn(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        greeting,
        email,
        setEmail,
        logInFail,
        setLogInFail,
        setWantsToSignIn,
        wantsToSignIn,
        password,
        setPassword,
        signInWithGoogle,
        isAuthenticated,
        setIsAuthenticated,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        userSignIn,
        setUserSignIn,
        signIn,
        signUp,
        logOut,
      }}
    >
      <Navbar />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
